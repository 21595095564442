import React, { useState, useEffect, FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { ThemeProvider } from '@material-ui/styles'
import LunchAppBar from 'components/LunchAppBar'
import lunchesService from 'services/lunches'
import { LunchPlan } from 'models/lunch'
import LunchPlans from 'components/LunchPlans'
import SlackLoginButton from 'components/SlackLoginButton'
import { theme } from 'theme'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '3rem'
  }
}))

const App: FC = () => {
  const [lunches, setLunches] = useState<LunchPlan[]>([])
  const [existingLunches, setExistingLunches] = useState<LunchPlan[]>([])
  const classes = useStyles()
  const { user, logout } = useAuth()

  useEffect(() => {
    if (user) {
      lunchesService.setToken(user.token)
      lunchesService
        .getAllExistingLunches()
        .then(existingLunches => setExistingLunches(existingLunches))
        .catch(() => logout())
      lunchesService
        .getAllUserLunches()
        .then(lunches => setLunches(lunches))
        .catch(() => logout())
    } else {
      localStorage.removeItem('user')
      lunchesService.setToken('')
    }
  }, [user, logout])

  return (
    <ThemeProvider theme={theme}>
      <LunchAppBar />
      <Container maxWidth="md" className={classes.container}>
        {user ? (
          <LunchPlans lunches={lunches} existingLunches={existingLunches} setLunches={setLunches} />
        ) : (
          <SlackLoginButton />
        )}
      </Container>
    </ThemeProvider>
  )
}

export default App
