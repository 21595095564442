import React, { FC, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Grid from '@material-ui/core/Grid'
import { useAuth } from 'hooks/useAuth'
import { Avatar, Link } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subTitle: {
    textAlign: 'center',
    backgroundColor: '#FFF',
    color: '#5B4EAD',
    margin: "0 5px"
  },
  toolBar: {
    backgroundColor: '#FFF',
    color: '#5B4EAD'
  },
  userIcon: {
    textAlign: 'right'
  }
}))

interface Props {}

const LunchAppBar: FC<Props> = () => {
  const classes = useStyles()
  const { user, logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    logout()
    handleClose()
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <Grid container alignItems="center">
            <Grid item xs></Grid>
            <Grid item xs>
              <Typography variant="h5" className={classes.title}>
                Random lunch planner
              </Typography>
              <Typography variant="subtitle1" className={classes.subTitle}>
                Feedback? Join 
                <Link
                  href={"slack://channel?id=CP54656PM&team=T0252T2EC"}
                  target="_blank"
                  color="primary"
                  className={classes.subTitle}
                >
                  #lunchplanner-feedback
                </Link>
                on slack!
              </Typography>
            </Grid>
            <Grid item xs>
              {!!user && (
                <div className={classes.userIcon}>
                  <IconButton onClick={handleMenu} color="inherit">
                    {user.user.profilePicture ? (
                      <Avatar src={user.user.profilePicture} />
                    ) : (
                      <AccountCircle />
                    )}
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLogOut}>Log out</MenuItem>
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default LunchAppBar
