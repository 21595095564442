import axios from 'axios'
import user from 'models/user'

const baseUrl = '/api'

const authenticate = (code: string) =>
  axios
    .post<user>(`${baseUrl}/auth`, {
      code,
      redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT_URI
    })
    .then(response => response.data)

const logOut = (token: string) =>
  axios.post<string>(`${baseUrl}/logout`, null, {
    headers: { Authorization: token }
  })

export default { authenticate, logOut }
