import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import EventIcon from '@material-ui/icons/Event'
import ScheduleIcon from '@material-ui/icons/Schedule'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { CreateLunch } from 'models/lunch'
import lunchesService from 'services/lunches'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F9F9F9',
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(1)
  },
  timeWrapper: {
    paddingRight: theme.spacing(2)
  },
  slackButton: {
    marginTop: theme.spacing(2)
  },
  title: {
    fontWeight: 'bold'
  },
  disabled: {
    color: 'rgba(69, 69, 69, 0.601262)'
  },
  detailsRow: {
    fontSize: '0.8rem'
  }
}))

type LunchPlanCardProps = {
  partnerName?: string
  startTime: Date
  endTime: Date
  deleteLunch?: (lunchId: string) => any
  lunchId: string
  conversationId?: string | null
  existingLunch?: boolean
  appendLunchPlan?: Function
}

const LunchPlanCard: React.FC<LunchPlanCardProps> = ({
  partnerName,
  startTime,
  endTime,
  lunchId,
  conversationId,
  existingLunch,
  appendLunchPlan = () => {},
  deleteLunch = () => {}
}) => {
  const classes = useStyles()
  const { logout } = useAuth()

  const prettyTime = (date: Date) =>
    date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  const paperClasses = partnerName ? classes.root : `${classes.root} ${classes.disabled}`

  const handleJoinLunch = () => {
    const lunchPlan: CreateLunch = {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      location: 'Helsinki'
    }
    lunchesService
          .create(lunchPlan)
          .then(lunchResp => {
            appendLunchPlan(lunchResp)
          })
          .catch((e) => logout())
  }

  const lunchPlanCardTitle = () => {
    if (!existingLunch) {
      return partnerName ? `Lunch with ${partnerName}` : 'Pending lunch'
    } else {
      return `Existing lunch plan`
    }
  }

  const lunchPlanCardButton = () => {
    if (!existingLunch) {
      return partnerName ? (
        <Button
          href={`slack://user?team=${process.env.REACT_APP_TEAM_ID}&id=${conversationId}`}
          variant="contained"
          color="primary"
          className={classes.slackButton}
        >
          Open Slack
        </Button>
      ) : (
          <Button
            variant="contained"
            color="secondary"
            className={classes.slackButton}
            onClick={() => deleteLunch(lunchId)}
          >
            Cancel
        </Button>
        )
    } else if (existingLunch) {
      return <Button
        variant="contained"
        color="secondary"
        className={classes.slackButton}
        onClick={handleJoinLunch}
      >
        Join
            </Button>
    }
  }

  return (
    <Paper className={paperClasses}>
      <Typography gutterBottom variant="h6" className={classes.title}>
        {lunchPlanCardTitle()}
      </Typography>
      <Grid item container className={classes.detailsRow}>
        <Grid item>
          <EventIcon fontSize="small"></EventIcon>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.timeWrapper}>
            {startTime.toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item>
          <ScheduleIcon fontSize="small"></ScheduleIcon>
        </Grid>
        <Grid>
          <Typography variant="body2">
            {prettyTime(startTime)} - {prettyTime(endTime)}
          </Typography>
        </Grid>
      </Grid>
      {lunchPlanCardButton()}
    </Paper>
  )
}

export default LunchPlanCard
