import React, { useState, useEffect, FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LunchPlanCard from 'components/LunchPlanCard'
import CreateLunchPlan from 'components/CreateLunchPlan'
import { LunchPlan } from 'models/lunch'
import lunchesService from 'services/lunches'
import MemberModel from 'models/member'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  newPlanButton: {
    padding: theme.spacing(3, 5),
    fontWeight: 'bold',
    fontSize: '1.1rem',
    backgroundColor: '#A389FF',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#705bcb'
    }
  },
  newPlanButtonContainer: {
    textAlign: 'center'
  },
  cardRowTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(4)
  }
}))

interface LunchPlansProps {
  lunches: LunchPlan[]
  existingLunches: LunchPlan[]
  setLunches: Function
}

const LunchPlans: FC<LunchPlansProps> = ({ lunches, existingLunches, setLunches }) => {
  const classes = useStyles()
  const { user, logout } = useAuth()
  const [confirmedLunches, setConfirmedLunches] = useState<LunchPlan[]>([])
  const [unconfirmedLunches, setUnconfirmedLunches] = useState<LunchPlan[]>([])
  const [allUnconfirmedLunches, setAllUnconfirmedLunches] = useState<LunchPlan[]>([])

  const sortByStartTime = (lunches: LunchPlan[]): LunchPlan[] => {
    return lunches.sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
  }

  useEffect(() => {
    setConfirmedLunches(sortByStartTime(lunches.filter(lunch => lunch.matchDetails)))
    setUnconfirmedLunches(sortByStartTime(lunches.filter(lunch => !lunch.matchDetails)))
    setAllUnconfirmedLunches(
      sortByStartTime(existingLunches.filter(existingLunches => !existingLunches.matchDetails))
    )
  }, [lunches, existingLunches])

  const updateConfirmedandExistingLunches = (lunchPlan: LunchPlan) => {
    setConfirmedLunches(sortByStartTime([...confirmedLunches, lunchPlan]))
    setAllUnconfirmedLunches(
      sortByStartTime(allUnconfirmedLunches.filter(unconfirmedLunch => {
        return !lunchPlan.matchDetails!.planIds.includes(unconfirmedLunch._id)
      }))
    )
  }

  const appendLunchPlan = (lunchPlan: LunchPlan) => {
    lunchPlan.isMatched
      ? updateConfirmedandExistingLunches(lunchPlan)
      : setUnconfirmedLunches(sortByStartTime([...unconfirmedLunches, lunchPlan]))
  }

  const deleteLunch = (lunchId: string) => {
    lunchesService
      .remove(lunchId)
      .then(() => setLunches(lunches.filter(lunch => lunch._id !== lunchId)))
      .catch(() => logout())
  }

  const getPartnerName = (members: MemberModel[]) => {
    return members.filter(member => member.name !== user!.user.name)[0].name
  }

  return (
    <Grid container justify="space-evenly" alignItems="stretch" spacing={5}>
      <Grid item xs={12} className={classes.newPlanButtonContainer}>
        <CreateLunchPlan appendLunchPlan={appendLunchPlan} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="h4" className={classes.cardRowTitle}>
          Planned lunches
        </Typography>
        {confirmedLunches.map(lunch => (
          <LunchPlanCard
            key={lunch._id}
            partnerName={getPartnerName(lunch.matchDetails!.memberDetails)}
            startTime={lunch.matchDetails!.overlapStart}
            endTime={lunch.matchDetails!.overlapEnd}
            lunchId={lunch._id}
            conversationId={lunch.matchDetails!.conversationId}
          />
        ))}
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="h4" className={classes.cardRowTitle}>
          Pending plans
        </Typography>
        {unconfirmedLunches.map(lunch => (
          <LunchPlanCard
            key={lunch._id}
            startTime={lunch.startTime}
            endTime={lunch.endTime}
            deleteLunch={deleteLunch}
            lunchId={lunch._id}
          />
        ))}
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="h4" className={classes.cardRowTitle}>
          Existing lunches
        </Typography>
        {allUnconfirmedLunches.map(lunch => (
          <LunchPlanCard
            key={lunch._id}
            startTime={lunch.startTime}
            endTime={lunch.endTime}
            lunchId={lunch._id}
            existingLunch={true}
            appendLunchPlan={appendLunchPlan}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default LunchPlans
