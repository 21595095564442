import React, { useState, useContext, createContext, FC, useEffect } from 'react'
import authService from 'services/auth'
import userModel from 'models/user'

interface UseAuth {
  user: userModel | null
  login: Function
  logout: Function
}

const authContext = createContext<UseAuth>({} as UseAuth)

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: FC = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext)

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const [user, setUser] = useState<userModel | null>(() => {
    const storedUser = localStorage.getItem('user')
    return storedUser ? JSON.parse(storedUser) : null
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    if (url.pathname === '/login' && code) {
      authService
        .authenticate(code)
        .then(user => {
          setUser(user)
          localStorage.setItem('user', JSON.stringify(user))
        })
        .finally(() => window.history.replaceState({}, '', '/'))
    }
  }, [])

  const login = () => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    if (url.pathname === '/login' && code) {
      authService
        .authenticate(code)
        .then(user => {
          setUser(user)
          localStorage.setItem('user', JSON.stringify(user))
        })
        .finally(() => window.history.replaceState({}, '', '/'))
    }
  }

  const logout = () => {
    if (user) {
      authService.logOut(user.token).finally(() => setUser(null))
    }
  }

  return { user, login, logout }
}
