import React from 'react'
import { Grid } from '@material-ui/core'

const loginUrl = `https://slack.com/oauth/authorize?scope=identity.basic,identity.email,identity.avatar&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LOGIN_REDIRECT_URI}&team=T0252T2EC`

export default () => (
  <Grid container direction="column" alignItems="center">
    <Grid item>
      <a href={loginUrl}>
        <img
          alt="Sign in with Slack"
          height="40"
          width="172"
          src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
          srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
        />
      </a>
    </Grid>
  </Grid>
)
