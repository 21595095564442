import React, { useState, FC } from 'react'
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers'
import AccessTime from '@material-ui/icons/AccessAlarm'
import DateFnsUtils from '@date-io/date-fns'
import { setHours, isValid, setMinutes, differenceInHours } from 'date-fns'
import lunchesService from 'services/lunches'
import { CreateLunch } from 'models/lunch'
import { isBefore } from 'date-fns/esm'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  grid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.1'
  },
  title: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  button: {
    padding: theme.spacing(3, 5)
  }
}))

interface CreateLunchPlanProps {
  appendLunchPlan: Function
}

const CreateLunchPlan: FC<CreateLunchPlanProps> = ({ appendLunchPlan }) => {
  const classes = useStyles()
  const { logout } = useAuth()
  const buttonClasses = `${classes.button} ${classes.root} ${classes.text}`

  const [isValidTime, setIsValidTime] = useState(true)
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<Date>(new Date())
  const [startTime, setStartTime] = useState<Date>(setMinutes(setHours(date, 11), 0))
  const [endTime, setEndTime] = useState<Date>(setMinutes(setHours(date, 13), 0))

  const toggleDialog = () => setOpen(!open)

  const submitPlan = async () => {
    if (!isValid(date) || !isValid(startTime) || !isValid(endTime)) return

    const start = setMinutes(setHours(date, startTime.getHours()), startTime.getMinutes())
    const end = setMinutes(setHours(date, endTime.getHours()), endTime.getMinutes())

    // validation:
    // start not in past
    // end not before start
    // difference more then one hour
    if (isBefore(end, start) || isBefore(start, new Date()) || differenceInHours(end, start) < 1) {
      setIsValidTime(false)
      return
    }
    setIsValidTime(true)

    const lunchPlan: CreateLunch = {
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      location: 'Helsinki'
    }
    lunchesService
      .create(lunchPlan)
      .then(lunchResp => appendLunchPlan(lunchResp))
      .catch(() => logout())
    setOpen(false)
  }

  return (
    <>
      <Button variant="contained" className={buttonClasses} onClick={toggleDialog}>
        + CREATE LUNCH PLAN
      </Button>
      <Dialog open={open} onClose={toggleDialog}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid container direction="column" justify="flex-end" alignItems="stretch">
            <Grid item>
              <DialogTitle className={`${classes.title} ${classes.text}`}>
                CREATE LUNCH PLAN
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item className={classes.grid}>
              <DialogContentText className={classes.text}>Select date and time</DialogContentText>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                  <KeyboardDatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    value={date}
                    minDate={new Date()}
                    onChange={e => e && setDate(e)}
                  />
                </Grid>
                <Grid className={classes.grid} container direction="row" justify="space-around">
                  <Grid item xs={5}>
                    <KeyboardTimePicker
                      ampm={false}
                      variant="inline"
                      value={startTime}
                      onChange={e => e && setStartTime(e)}
                      keyboardIcon={<AccessTime />}
                    />
                    <DialogContentText>From</DialogContentText>
                  </Grid>
                  <Grid item xs={5}>
                    <KeyboardTimePicker
                      ampm={false}
                      variant="inline"
                      value={endTime}
                      onChange={e => e && setEndTime(e)}
                      keyboardIcon={<AccessTime />}
                    />
                    <DialogContentText>To</DialogContentText>
                  </Grid>
                </Grid>
                <Grid item className={classes.grid}>
                  {!isValidTime && (
                    <DialogContentText color="error" align="center">
                      Invalid time range
                    </DialogContentText>
                  )}
                  <DialogContentText align="center">
                    Select time frame when you're available for lunch (minimum 1 hour)
                  </DialogContentText>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <Grid item className={classes.grid}>
            <DialogActions>
              <Button variant="contained" className={buttonClasses} onClick={submitPlan}>
                MAKE A PLAN
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default CreateLunchPlan
