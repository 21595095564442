import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ABADDD',
      main: '#7571C2',
      dark: '#5B4EAD',
      contrastText: '#fff'
    },
    secondary: {
      light: '#D8CDFF',
      main: '#A389FF',
      dark: '#8549F8',
      contrastText: '#fff'
    }
  }
})
